<template>
    <div class="incubator-final">
        <div
            v-if="isFinish"
            class="deadline"
        >
            {{ $t('IncubatorFinal.index.deadline') }}
        </div>
        <div
            v-else
            class="web-content"
        >
            <div class="process-structure">
                <div class="apply-process-body">
                    <div class="process-page-title-row">
                        <div class="process-page-title">
                            {{ $t('IncubatorFinal.index.title') }}
                        </div>
                    </div>
                    <v-stepper
                        alt-labels
                        :value="nowStep"
                        class="stepper"
                    >
                        <v-stepper-header>
                            <v-stepper-step
                                :color="stepColor(1)"
                                :complete="nowStep > 1"
                                step="1"
                            >
                                {{ $t('IncubatorFinal.index.stepper[0]') }}
                            </v-stepper-step>

                            <v-divider />

                            <v-stepper-step
                                :color="stepColor(2)"
                                :complete="nowStep > 2"
                                step="2"
                            >
                                {{ $t('IncubatorFinal.index.stepper[1]') }}
                            </v-stepper-step>
                        </v-stepper-header>
                    </v-stepper>
                    <!-- Child Router -->
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import API from '@/api';

export default {
    components: {},
    data() {
        return {
            session: '',
        };
    },
    computed: {
        ...mapGetters('mAuth', ['isLogin']),
        applyId() {
            return this.$route.params.id;
        },
        nowStep() {
            switch (this.$route.name) {
                case 'IncubatorFinalUpload':
                    return 1;
                case 'IncubatorFinalComplete':
                    return 2;
                default:
                    return 1;
            }
        },
        isFinish() {
            return false;
        },
    },
    created() {
        this.init();
    },
    mounted() { },
    methods: {
        ...mapMutations('mNotification', ['setAlert']),
        ...mapMutations('mLoading', ['setLoading']),
        ...mapActions('mIncubatorApply', ['loadApply']),
        async init() {
            try {
                const res = await API.Setting.PublicInfo();
                this.session = res.data.IncubatorSession.session;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.session-error'),
                    color: 'error',
                });
            }
            if (!this.isLogin) {
                this.setAlert({
                    message: this.$t('alertMessage.login-update'),
                    color: 'primary',
                });
                this.$router.replace({ name: 'Login' });
                return;
            }
            try {
                this.loadApply(this.applyId);
            } catch (e) {
                console.log(e);
            }
        },
        stepColor(val) {
            if (this.nowStep >= val) {
                return '#F97E2B';
            }
            return '';
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/apply.scss';

.incubator-final {
  display: flex;
  justify-content: center;
}

.deadline{
  padding: 50px 10px;
}
</style>
